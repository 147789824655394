import React from 'react';
import { useLocation } from '@reach/router';

import useUserLangOrDefault from '../../hooks/useUserLangOrDefault';
import NotFoundEn from './index.en';
import NotFoundPt from './index.pt';

export default () => {
  const lang = useUserLangOrDefault();
  const { pathname } = useLocation();

  if (pathname.startsWith('/en')) return <NotFoundEn />;
  if (pathname.startsWith('/pt')) return <NotFoundPt />;

  if (lang === 'en') return <NotFoundEn />;

  return <NotFoundPt />;
};
