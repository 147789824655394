import React from 'react';

import SEO from '../../common/SEO';
import useStaticTranslation from '../../../hooks/useStaticTranslation';
import SolidButton from '../../common/buttons/SolidButton';
import { navigate } from 'gatsby';

import * as styles from './NotFoundPage.module.scss';
import { useLocalizedPath } from '../../../hooks/useLocalized';

const NotFoundPage = () => {
  const { t } = useStaticTranslation();
  const { localizedPath } = useLocalizedPath('/');

  return (
    <div className={styles.container}>
      <SEO title={t('meta.title')} />
      <div className={styles.content}>
        <div className={styles.text404}>
          <span className={styles.firstChar}>4</span>
          <span className={styles.secondChar}>0</span>
          <span className={styles.thirdChar}>4</span>
        </div>
        <div className={styles.message}>
          <h3 className={styles.title}>{t('content.title')}</h3>
          <p className={styles.subtitle}>{t('content.subtitle')}</p>
        </div>
        <SolidButton
          className={styles.goHomeButton}
          title={t('content.goHomeButton')}
          onClick={() => navigate(localizedPath)}
        />
      </div>
    </div>
  );
};

export default NotFoundPage;
