// extracted by mini-css-extract-plugin
export var container = "NotFoundPage-module--container--90ee6";
export var content = "NotFoundPage-module--content--f9fed";
export var firstChar = "NotFoundPage-module--first-char--dfa52";
export var goHomeButton = "NotFoundPage-module--go-home-button--b950e";
export var message = "NotFoundPage-module--message--2f8c2";
export var secondChar = "NotFoundPage-module--second-char--54208";
export var subtitle = "NotFoundPage-module--subtitle--0254b";
export var text404 = "NotFoundPage-module--text-404--1d0ef";
export var thirdChar = "NotFoundPage-module--third-char--0b824";
export var title = "NotFoundPage-module--title--fdbaf";