import React from 'react';

import Layout from '../../components/common/Layout';
import { StaticTranslationProvider } from '../../contexts/StaticTranslationContext';
import LanguageContext from '../../contexts/LanguageContext';
import { notFoundPageStrings } from '../../resources/strings/strings-en';
import NotFoundPage from '../../components/pages/NotFoundPage';

const NotFoundPage_en = () => (
  <StaticTranslationProvider translations={notFoundPageStrings}>
    <LanguageContext.Provider value="en">
      <Layout footer={false}>
        <NotFoundPage />
      </Layout>
    </LanguageContext.Provider>
  </StaticTranslationProvider>
);

export default NotFoundPage_en;
